.welcome-page {
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 75px 0 0 0;
  margin: 0 auto;

  .input-group {
    display: flex;
    margin-top: 20px;
    padding-top: 0;

    input {
      width: auto;
      height: 50px;
      flex-grow: 1;
      border-color: white;
    }

    button {
      margin-left: 0;
      padding: 0 30px;
      height: 50px;

      &:hover {
        background: black;
      }
    }
  }

  .left {
    width: auto;
    padding: 60px 0px 20px 0;
    margin-right: 50px;
  }

  .right {
    position: relative;
    min-width: 320px;
  }

  h2 {
    color: white;
    font-size: 34px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 45px;
    padding: 0;
    margin: 0;
  }

  .hero {
    display: flex;
    background-color: #04BF55;
    color: white;
    padding-top: 20px;
  }

  .phone {
    width: 100%;
    position: absolute;
  }

  p {
    font-size: 1.2em;
  }

  .row {
    display: flex;
  }
}


.features {
  &.row {
    margin-top: 120px;
  }

  flex-wrap: wrap;
}

.feature {
  display: flex;
  flex-grow: 1;
  width: 50%;
  padding-bottom: 50px;
  align-items: flex-start;

  &:nth-child(odd) {
    padding-right: 20px;
  }

  &:nth-child(even) {
    padding-left: 20px;
  }

  img {
    width: 80px;
  }

  h3 {
    font-weight: 900;
    font-size: 24px;
    line-height: 1.2em;
    text-transform: uppercase;
  }

  a {
    font-weight: bold;
    color: #04BF55;
    text-decoration: underline;

    &:hover {
      color: darken(#04BF55, 10%);
      text-decoration: underline;
    }
  }
}

.footer {
  background: black;
  color: white;
  text-align: center;
  padding: 40px 0;

  .row {
    flex-direction: column;
  }

  h2 {
    padding-bottom: 20px;
  }

  a {
    margin-top: 40px;
    height: 50px;
    padding: 12px 50px;
    color: white;
    background-color: #04BF55;
    font-weight: bold;
    font-size: 17px;
    max-width: 360px;
    align-self: center;
    border-radius: 3px;
    transition: background-color 0.3s;
    text-transform: uppercase;

    &:hover {
      color: white;
      background-color: darken(#04BF55, 10%);
    }
  }
}

.testimonials {
  display: flex;
  max-width: 500px;
  margin: 0 auto;
}

.testimonial {
  padding: 20px;
  font-size: 12px;
  height: 180px;

  span {
    font-weight: bold;
    font-size: 15px;
  }

}

@media only screen and (max-width: 600px) {


  .welcome-page {
    padding: 60px 0 0 0;

    h2 {
      font-size: 27px;
      line-height: 36px;
    }
    .row {
      flex-direction: column;
      padding: 0 20px;
    }

    .hero {
      .row {
        // color: white;
        padding: 0;
      }
    }

    .phone {
      position: relative;
      left: 20px;
      z-index: 2;
    }

    .left {
      margin-right: 0;
      padding: 0 20px;
    }
    .right {
      overflow: hidden;

      &::after {
        content: '';
        background: #fff;
        height: 80px;
        z-index: 1;
        width: 100%;
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
      }
    }

    .features {
      &.row {
        margin-top: 20px;
      }
    }

    .feature {
      width: auto;

      img {
        width: 70px;
      }

      &.feature:nth-child(odd) {
        padding-right: initial;
      }

      &.feature:nth-child(even) {
        padding-left: initial;
      }
    }

    .footer {
      padding: 30px 0;

      .testimonial {
        height: 200px;
      }

      h2 {
        font-size: 22px;
      }
    }
  }
}
