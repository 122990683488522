@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700,900&display=swap);
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: -2px 1px 7px rgba(0, 0, 0, 0.09); }
  header .inner {
    max-width: 1024px;
    display: flex;
    flex: 1 1;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center; }
  header img {
    box-sizing: content-box;
    box-sizing: initial; }
  header .logo {
    height: 55px; }

@media only screen and (max-width: 600px) {
  header .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
    text-align: right; }
  header .inner {
    padding: 0 10px; }
  header .logo {
    height: 40px; }
  .menu {
    font-size: .9em; } }

.loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loader img {
    -webkit-animation: rotation 2s infinite linear;
            animation: rotation 2s infinite linear;
    height: 70px;
    width: 70px; }

@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

footer {
  padding: 10px 0;
  text-align: center;
  color: #bbbbbb;
  font-size: .9em; }

.welcome-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 75px 0 0 0;
  margin: 0 auto; }
  .welcome-page .input-group {
    display: flex;
    margin-top: 20px;
    padding-top: 0; }
    .welcome-page .input-group input {
      width: auto;
      height: 50px;
      flex-grow: 1;
      border-color: white; }
    .welcome-page .input-group button {
      margin-left: 0;
      padding: 0 30px;
      height: 50px; }
      .welcome-page .input-group button:hover {
        background: black; }
  .welcome-page .left {
    width: auto;
    padding: 60px 0px 20px 0;
    margin-right: 50px; }
  .welcome-page .right {
    position: relative;
    min-width: 320px; }
  .welcome-page h2 {
    color: white;
    font-size: 34px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 45px;
    padding: 0;
    margin: 0; }
  .welcome-page .hero {
    display: flex;
    background-color: #04BF55;
    color: white;
    padding-top: 20px; }
  .welcome-page .phone {
    width: 100%;
    position: absolute; }
  .welcome-page p {
    font-size: 1.2em; }
  .welcome-page .row {
    display: flex; }

.features {
  flex-wrap: wrap; }
  .features.row {
    margin-top: 120px; }

.feature {
  display: flex;
  flex-grow: 1;
  width: 50%;
  padding-bottom: 50px;
  align-items: flex-start; }
  .feature:nth-child(odd) {
    padding-right: 20px; }
  .feature:nth-child(even) {
    padding-left: 20px; }
  .feature img {
    width: 80px; }
  .feature h3 {
    font-weight: 900;
    font-size: 24px;
    line-height: 1.2em;
    text-transform: uppercase; }
  .feature a {
    font-weight: bold;
    color: #04BF55;
    text-decoration: underline; }
    .feature a:hover {
      color: #038d3f;
      text-decoration: underline; }

.footer {
  background: black;
  color: white;
  text-align: center;
  padding: 40px 0; }
  .footer .row {
    flex-direction: column; }
  .footer h2 {
    padding-bottom: 20px; }
  .footer a {
    margin-top: 40px;
    height: 50px;
    padding: 12px 50px;
    color: white;
    background-color: #04BF55;
    font-weight: bold;
    font-size: 17px;
    max-width: 360px;
    align-self: center;
    border-radius: 3px;
    transition: background-color 0.3s;
    text-transform: uppercase; }
    .footer a:hover {
      color: white;
      background-color: #038d3f; }

.testimonials {
  display: flex;
  max-width: 500px;
  margin: 0 auto; }

.testimonial {
  padding: 20px;
  font-size: 12px;
  height: 180px; }
  .testimonial span {
    font-weight: bold;
    font-size: 15px; }

@media only screen and (max-width: 600px) {
  .welcome-page {
    padding: 60px 0 0 0; }
    .welcome-page h2 {
      font-size: 27px;
      line-height: 36px; }
    .welcome-page .row {
      flex-direction: column;
      padding: 0 20px; }
    .welcome-page .hero .row {
      padding: 0; }
    .welcome-page .phone {
      position: relative;
      left: 20px;
      z-index: 2; }
    .welcome-page .left {
      margin-right: 0;
      padding: 0 20px; }
    .welcome-page .right {
      overflow: hidden; }
      .welcome-page .right::after {
        content: '';
        background: #fff;
        height: 80px;
        z-index: 1;
        width: 100%;
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0; }
    .welcome-page .features.row {
      margin-top: 20px; }
    .welcome-page .feature {
      width: auto; }
      .welcome-page .feature img {
        width: 70px; }
      .welcome-page .feature.feature:nth-child(odd) {
        padding-right: 0;
        padding-right: initial; }
      .welcome-page .feature.feature:nth-child(even) {
        padding-left: 0;
        padding-left: initial; }
    .welcome-page .footer {
      padding: 30px 0; }
      .welcome-page .footer .testimonial {
        height: 200px; }
      .welcome-page .footer h2 {
        font-size: 22px; } }

* {
  box-sizing: border-box; }

body {
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  color: black;
  opacity: 1; }

.row {
  max-width: 1024px;
  margin: 0 auto; }

a {
  cursor: pointer;
  color: #3cb34f; }
  a:hover {
    color: #3cb34f; }

input,
button {
  outline: none;
  padding: 0 15px; }
  input[type='button'],
  button[type='button'] {
    cursor: pointer; }

input {
  width: 600px;
  color: black;
  height: 40px; }

textarea {
  width: 600px;
  min-height: 200px;
  padding: 10px 15px;
  color: black;
  outline: none; }

button {
  color: white;
  cursor: pointer;
  background-color: black;
  border: none;
  transition: all 200ms;
  margin-left: 20px; }
  button:hover {
    background-color: #3cb34f; }
  button.solid {
    height: 40px;
    font-weight: bold; }

input {
  border: 1px solid #000;
  border-radius: 0;
  box-shadow: none !important;
  -webkit-appearance: none; }

.input-group {
  display: inline-flex; }
  .input-group input {
    border-right-width: 0px; }

button.link,
footer a.link {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #3cb34f;
  outline: none;
  margin: 0; }

h2 {
  font-weight: 700; }

.form-validation.error {
  color: red;
  border-color: red; }
  .form-validation.error::-webkit-input-placeholder {
    color: red; }
  .form-validation.error::-ms-input-placeholder {
    color: red; }
  .form-validation.error::placeholder {
    color: red; }

#cloudsponge-address-book .cloudsponge-topbar {
  background-color: black !important; }

#cloudsponge-address-book .cloudsponge-copyright {
  display: none; }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  input[type='email'],
  button[type='email'] {
    width: auto;
    text-align: center; }
  button {
    margin-top: 15px;
    height: 50px;
    margin-left: 0; }
    button.solid {
      width: 100%;
      height: 50px; }
  input {
    border: 1px solid #000;
    border-right-width: 1px;
    width: auto;
    height: 50px; }
  input[type=email] {
    width: 100%;
    text-align: left; }
  textarea {
    width: 100%; }
  .input-group {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .input-group input {
      border-right-width: 1px; } }

.privacy-policy {
  padding-bottom: 40px; }
  .privacy-policy h1 {
    font-size: 34px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 45px;
    padding: 30px 0;
    margin: 0; }

