// * {
  // outline: 1px solid red;
// }

* {
  box-sizing: border-box;
}

@import '~antd/dist/antd.css';

body {
  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,900&display=swap');
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  color: black;
  opacity: 1;
}

.row {
  max-width: 1024px;
  margin: 0 auto;
}

a {
  cursor: pointer;
  color: #3cb34f;

  &:hover {
    color: #3cb34f;
  }
}

input,
button {
  outline: none;
  padding: 0 15px;

  &[type='button'] {
    cursor: pointer;
  }
}

input {
  width: 600px;
  color: black;
  height: 40px;
}

textarea {
  width: 600px;
  min-height: 200px;
  padding: 10px 15px;
  color: black;
  outline: none;
}

button {
  color: white;
  cursor: pointer;
  background-color: black;
  border: none;
  transition: all 200ms;
  margin-left: 20px;

  &:hover {
    background-color: #3cb34f;
  }

  &.solid {
    height: 40px;
    font-weight: bold;
  }
}

input {
  border: 1px solid #000;
  border-radius: 0;
  box-shadow: none !important;
  -webkit-appearance: none;
}

.input-group {

  display: inline-flex;

  input {
    border-right-width: 0px;
  }
}

button.link,
footer a.link  {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #3cb34f;
  outline: none;
  margin: 0;
}


h2 {
  font-weight: 700;
}

.form-validation {
  &.error {
    color: red;
    border-color: red;
    &::placeholder {
      color: red;
    }
  }
}



// Cloud Sponge
#cloudsponge-address-book {
  .cloudsponge-topbar {
    background-color: black !important;
  }

  .cloudsponge-copyright {
    display: none;
  }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {

  input,
  button {
    &[type='email'] {
      width: auto;
      text-align: center;
    }
  }

  button {
    margin-top: 15px;
    height: 50px;
    margin-left: 0;

    &.solid {
      width: 100%;
      height: 50px;
    }
  }

  input {
    border: 1px solid #000;
    border-right-width: 1px;
    width: auto;
    height: 50px;
  }

  input[type=email] {
    width: 100%;
    text-align: left;
  }

  textarea {
    width: 100%;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    width: 100%;

    input {
      border-right-width: 1px;
    }
  }
}
