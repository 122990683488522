header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: -2px 1px 7px rgba(0, 0, 0, 0.09);

  .inner {
    max-width: 1024px;
    display: flex;
    flex: 1 1;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  img {
    box-sizing: initial;
  }

  .logo {
    height: 55px;
  }
}


@media only screen and (max-width: 600px) {
  header {

    .truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 180px;
      text-align: right;
    }

    .inner {
      padding: 0 10px;
    }

    .logo {
      height: 40px;
    }
  }

  .menu {
    font-size: .9em;
  }
}
