.privacy-policy {
  h1 {
    font-size: 34px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 45px;
    padding: 30px 0;
    margin: 0;
  }

  padding-bottom: 40px;
}

